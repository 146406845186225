import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/pages/home-page.vue'
import PortfolioPage from '@/pages/portfolio-page.vue'

// Set page routes
const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: PortfolioPage
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
