<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark container-fluid" :class="{ 'sticky-top': isSticky }"
    id="mainNav">
    <!-- Logo -->
    <a class="navbar-brand" href="#page-top">
      <img src="/assets/img/logo.png" alt="Henry Kirk">
    </a>
    <!-- Mobile burger menu -->
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navCollapse"
      aria-controls="navCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <!-- Nav items -->
    <div class="collapse navbar-collapse" id="navCollapse">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item" v-for="link in navLinks" :key="link.name">
          <a :href="'#' + link.name" target="_self" class="nav-link" :class="{ 'active': link.isActive }">{{ link.name }}</a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'KirkNavigation',
  data () {
    return {
      navLinks: [
        { name: 'portfolio', isActive: false },
        { name: 'about', isActive: false },
        { name: 'skills', isActive: false },
        { name: 'contact', isActive: false }
      ],
      isSticky: false
    }
  },
  methods: {
    checkIsNavSticky () {
      const SCROLL_OFFSET = 10
      const MAX_WIDTH = 992 // pixels
      // Collapse navbar if scrolled down page or a small device
      this.isSticky = !!(((window.scrollY >= SCROLL_OFFSET) || (window.innerWidth < MAX_WIDTH)))
    },
    handleScroll () {
      this.checkIsNavSticky()

      // Set nav items visually active when section in view
      const links = this.navLinks
      const offset = 100
      links.forEach((link) => {
        const el = document.getElementById(link.name)
        const elTop = el.getBoundingClientRect().top - offset
        const elBottom = el.getBoundingClientRect().bottom - offset
        if (elTop >= 0 || elBottom <= 0) {
          link.isActive = false
        } else if (elTop <= 0 && elBottom >= 0) {
          link.isActive = true
        }
      })
    },
    handleResize () {
      this.checkIsNavSticky()
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleResize)
    this.checkIsNavSticky()
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleScroll)
  }
}
</script>

<style lang='scss'>
#mainNav {
  background-color: #000000; /* fallback */
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  z-index: 9;
  img {
    /* logo */
    max-width: 2em;
    @include image-glow;
  }
  .navbar-toggler {
    font-size: 1.5rem;
    right: 0;
    padding: 0;
    text-transform: uppercase;
    border: 0;
    line-height: initial;
    span {
      display: block;
      height: 2px;
      margin: 6px;
      width: 30px;
      background: #ffffff;
      opacity: 1;
      left: 0;
      @include transform(rotate(0deg));
      @include transition($animation-duration ease-in-out);
    }
    span:nth-child(2) {
      top: 18px;
    }
    span:nth-child(3) {
      top: -8px;
    }
    &[aria-expanded="true"] {
      /* menu is open */
      span:nth-child(1) {
        top: 8px;
        position: relative;
        @include transform(rotate(135deg));
      }
      span:nth-child(2) {
        opacity: 0;
        left: -60px;
      }
      span:nth-child(3) {
        position: relative;
        @include transform(rotate(-135deg));
      }
    }
  }
  a {
    font-size: 90%;
    font-weight: 700;
    padding: 0.75em 0;
    letter-spacing: 0.125rem;
    color: #ffffff;
    text-transform: uppercase;
  }
  a.active,
  a:hover {
    color: #00bcd4;
  }
  @media (max-width: 992px) {
    /* only small devices */
    .navbar-nav {
      height: 100vh;
      /* make mobile menu full height */
      li.nav-item {
        padding: 6vh 0px;
        font-size: 1.5em;
        text-align: center;
      }
    }
  }
}
@media (min-width: 992px) {
  #mainNav {
    border: none;
    background-color: transparent;
    position: absolute;
    @include transition(padding-top $animation-duration, padding-bottom $animation-duration);
    &.sticky-top {
      position: fixed;
      padding-top: 0;
      padding-bottom: 0;
      background-color: #000000; /* fallback */
      background-color: rgba(0, 0, 0, 0.9);
      @include box-shadow;
      .navbar-brand {
        font-size: 1.25em;
        padding: 12px 0;
      }
    }
    .navbar-brand {
      font-size: 1.75em;
      @include transition(all $animation-duration);
    }
    a {
      padding: 0.8em !important;
    }
  }
}
</style>
