<template>
  <form @submit.prevent="onSubmit" method="post" action="/contact">
      <b-alert variant="success" dismissible max="10" :show="showSuccessAlert">Thanks for contacting me!</b-alert>
      <div class="row">
          <div class="col-lg-6 align-self-center text-center">
              <h2 class="section-heading text-uppercase d-inline-block text-right mw-100"><span style="font-size: 7rem; position: relative; left: 25px;">Let's</span><br><span class="text-grow font-weight-bold">Talk!</span></h2>
              <h3 class="section-subheading">Questions? Want to work together?</h3>
          </div>
          <div class="col-lg-6">
              <div class="form-group">
                  <input
                      v-model="form.name"
                      class="form-control"
                      id="name"
                      type="text"
                      placeholder="Your Name *"
                      required
                      :class="{ 'is-invalid': nameState === false }">
                  <div class="invalid-feedback">Please enter a valid name.</div>
              </div>
              <div class="form-group">
                  <input
                      v-model="form.email"
                      class="form-control"
                      id="email"
                      type="email"
                      placeholder="Your Email *"
                      required
                      :class="{ 'is-invalid': emailState === false }">
                  <div class="invalid-feedback">Please enter a valid email address.</div>
              </div>
              <div class="form-group">
                  <input
                      v-model="form.phone"
                      class="form-control"
                      id="phone"
                      type="tel"
                      placeholder="Your Phone *"
                      required
                      :class="{ 'is-invalid': phoneState === false }">
                  <div class="invalid-feedback">Please enter a valid phone number.</div>
              </div>
              <div class="form-group">
                  <textarea
                      v-model="form.message"
                      class="form-control"
                      id="message"
                      placeholder="Your Message *"
                      required
                      :class="{ 'is-invalid': messageState === false }"></textarea>
                  <div class="invalid-feedback">Please enter a valid message.</div>
              </div>
              <button
                  id="sendMessageButton"
                  class="btn btn-primary btn-xl text-uppercase"
                  type="submit">
                  <div v-if="showSpinner" class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <template v-else>Send Message</template>
              </button>
          </div>
      </div>
  </form>
</template>

<script>
import { defineComponent } from 'vue'
import axios from 'axios'
const qs = require('qs')

export default defineComponent({
  computed: {
    formattedName () {
      return this.form.name ? this.form.name.toUpperCase() : ''
    },
    formattedPhone () {
      return this.form.phone
        ? this.form.phone
          .replace(/[^0-9]/g, '')
          .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
        : ''
    },
    nameState () {
      const name = this.form.name
      if (name) {
        return name.length > 2
      } else {
        return null
      }
    },
    emailState () {
      const email = this.form.email
      if (email) {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return regex.test(email.toLowerCase())
      } else {
        return null
      }
    },
    phoneState () {
      const phone = this.form.phone
      const PHONE_NUM_LENGTH = 14
      if (phone) {
        return phone.length === PHONE_NUM_LENGTH
      } else {
        return null
      }
    },
    messageState () {
      const message = this.form.message
      if (message) {
        return message.length > 2
      } else {
        return null
      }
    }
  },
  data () {
    return {
      form: {
        name: null,
        email: null,
        phone: null,
        message: null
      },
      showSuccessAlert: false,
      showSpinner: false
    }
  },
  watch: {
    formattedName (newValue) {
      this.form.name = newValue
    },
    formattedPhone (newValue) {
      this.form.phone = newValue
    }
  },
  methods: {
    onSubmit (evt) {
      // Show loader/spinner on button for 800ms
      this.showSpinner = true
      setTimeout(() => {
        this.showSpinner = false
      }, 800)

      // Prevent form from reloading page
      evt.preventDefault()

      const self = this

      // Perform POST request
      axios.post('/contact',
        qs.stringify(this.form),
        { headers: { 'Content-type': 'application/x-www-form-urlencoded' } })
        .then(function (response) {
          console.log(response)
        })
        .catch(function (error) {
          console.log(error)
        })
      self.reset()
      self.showSuccessAlert = true
    },
    reset () {
      // Reset form
      this.form.name = ''
      this.form.email = ''
      this.form.phone = ''
      this.form.message = ''
    }
  }
})
</script>

<style lang='scss'>
    .text-grow {
        font-size: 10rem;
        @media(min-width: 1440px){
            font-size: 15rem;
        }
    }
    .form-group {
        margin-bottom: 25px;
        background-color: $black;
        input,
        textarea {
            padding: 20px;
            border-radius: 0px;
            border: none;
            box-shadow: none;
            border-bottom: $border-width solid $black;
            background-color: $black;
            color: $white !important;
            @include transition(border-bottom 0.5s);
            &.is-invalid {
                border-bottom: $border-width solid $primary;
            }
            &:focus {
                border-bottom: $border-width solid $secondary;
                background-color: $black;
            }
        }
        input.form-control {
            height: auto;
        }
        textarea.form-control {
            height: 248px;
        }
    }
    ::-webkit-input-placeholder,
    :-moz-placeholder,
    ::-moz-placeholder,
    :-ms-input-placeholder {
        font-weight: 700;
        color: $dark-gray;
    }
    #sendMessageButton {
        background-image: linear-gradient(to right, $primary, $secondary);
        background-size: 200% auto;
        border-radius: 0;
        border: 0;
        font-size: 18px;
        padding: 15px 45px;
        width: 100%;
        @include transition(0.3s all ease-in-out);
        &:active,
        &:focus,
        &:hover {
            background-position: right center;
        }
        &:active,
        &:focus {
            box-shadow: 0 0 0 0.2rem rgba(254, 209, 55,.5) !important;
        }
    }
</style>
