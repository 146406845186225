<template>
    <ul class="timeline">
        <li v-for="(item,index) in timelineItems" :key="index" :class="getAlternatingClass(index)">
            <div class="timeline-image">
                <img class="rounded-circle img-fluid" :src="'/assets/img/about/' + item.imageName" :alt="item.imageName">
            </div>
            <div class="timeline-panel">
                <div class="timeline-heading">
                    <div class="row">
                        <div class="col-lg-12">
                            <div v-if="item.date" class="date">
                                <span class="day">{{ ('0' + item.date.getDate()).slice(-2) }}</span>
                                <span class="month">{{ monthNames[item.date.getMonth()-1] }}</span>
                                <span class="year">{{ item.date.getFullYear() }}</span>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <h4 class="subheading">{{ item.subtitle }}</h4>
                        </div>
                    </div>
                </div>
                <div class="timeline-body">
                    <p class="text-muted">{{ item.description }}</p>
                </div>
            </div>
        </li>
        <li class="timeline-inverted">
            <div class="timeline-image">
                <i class="ion ion-md-help"></i>
            </div>
        </li>
    </ul>
</template>

<script>
export default {
  data () {
    return {
      timelineItems: [
        {
          date: new Date(2015, 8, 30),
          subtitle: 'Started at RIT',
          description: 'I was accepted to RIT and awarded the Presidential and Merit scholarships. I started in the Computer Exploration Program and switched into Web & Mobile Computing (B.S.) after my first year.',
          imageName: 'rit.jpg'
        },
        {
          date: new Date(2015, 9, 8),
          subtitle: 'Started Working at RIT Tech Crew',
          description: 'At RIT Tech Crew, I lead teams of students to set up and operate audio, lighting, and power equipment for on-campus events.',
          imageName: 'tc.png'
        },
        {
          date: new Date(2017, 6, 15),
          subtitle: 'Started Working at RIT\'s Undergraduate Admissions Office',
          description: 'I worked for the admissions office as their Web Service Programmer Analyst from June 2017 to December 2017. I was responsible for design and development of RIT\'s Undergraduate Admissions website, RIT\'s Graduate Admissions website, and The Loop – a student social network. I primarily worked with PHP, Javascript, Joomla!, and Adobe illustrator.',
          imageName: 'rit-admissions.png'
        },
        {
          date: new Date(2018, 1, 20),
          subtitle: 'Started Working at EagleDream Technologies',
          description: 'For my second RIT co-op, I worked full-time at EagleDream technologies from January to August 2018. As a Web Developer, I was responsible for design, coding, and support of  websites,  web applications, and apps from layout to function and according to a client\'s specifications. I worked primarily with PHP, JavaScript, SASS, and ionic/Angular.',
          imageName: 'edt.png'
        },
        {
          date: new Date(2018, 10, 9),
          subtitle: 'JPMorgan Chase\'s 50th Code for Good Challenge',
          description: 'Code for Good is a hackathon in which students build innovative technology solutions for non-profit organizations. I participated in JPMorgan\'s Code for Good in Delaware and built a hybrid mobile app for GoodWill. My team ended up winning the competition, and our solution will continue to be developed into a production app.',
          imageName: 'chase.jpg'
        },
        {
          date: new Date(2019, 1, 15),
          subtitle: 'Study Abroad at RIT Croatia',
          description: 'For my last semester at RIT I decided to study at RIT\'s Zagreb campus.',
          imageName: 'rit-global.png'
        },
        {
          date: new Date(2019, 5, 10),
          subtitle: 'Graduated from RIT',
          description: 'I earned my Bachelor of Science in Web & Mobile Computing from the B. Thomas Golisano College of Computing and Information Sciences.',
          imageName: 'graduation.jpg'
        },
        {
          date: new Date(2019, 7, 15),
          subtitle: 'Started as Software Engineer at JPMorgan Chase',
          description: 'I started working as a Software Engineer as part of the Claims & Disputes team.',
          imageName: 'chase.jpg'
        },
        {
          date: new Date(2022, 7, 20),
          subtitle: 'Started as SDE at AWS',
          description: 'I started working for Amazon S3.',
          imageName: 'aws_logo.png'
        }
      ],
      monthNames: ['JAN.', 'FEB.', 'MAR.', 'APRIL', 'MAY', 'JUN.', 'JULY', 'AUG.', 'SEPT', 'OCT.', 'NOV.', 'DEC.']
    }
  },
  methods: {
    getAlternatingClass (index) {
      if (index % 2 === 1) {
        return 'timeline-inverted'
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang='scss'>
    .timeline {
        position: relative;
        padding: 0;
        list-style: none;
        &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 40px;
            width: 2px;
            margin-left: -1.5px;
            content: '';
            background-color: $black;
        }
        > li {
            position: relative;
            min-height: 50px;
            margin-bottom: 50px;
            &:after,
            &:before {
                display: table;
                content: ' ';
            }
            &:after {
                clear: both;
            }
            .timeline-panel {
                position: relative;
                float: right;
                width: 100%;
                padding: 0 20px 0 100px;
                text-align: left;
                &:before {
                    right: auto;
                    left: -15px;
                    border-right-width: 15px;
                    border-left-width: 0;
                }
                &:after {
                    right: auto;
                    left: -14px;
                    border-right-width: 14px;
                    border-left-width: 0;
                }
            }
            .timeline-image {
                position: absolute;
                z-index: 100;
                left: 0;
                width: 80px;
                height: 80px;
                margin-left: 0;
                text-align: center;
                color: white;
                border: $border-width solid $black;
                border-radius: 100%;
                background-image: linear-gradient(to right, $primary, $secondary);
                i {
                    font-size: 60px;
                    margin-top: 50%;
                    transform: translate(0, -50%);
                }
            }
            &.timeline-inverted > .timeline-panel {
                float: right;
                padding: 0 20px 0 100px;
                text-align: left;
                &:before {
                    right: auto;
                    left: -15px;
                    border-right-width: 15px;
                    border-left-width: 0;
                }
                &:after {
                    right: auto;
                    left: -14px;
                    border-right-width: 14px;
                    border-left-width: 0;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
            @media(min-width: 992px) {
                min-height: 150px;
                .timeline-panel {
                    padding: 0 20px 20px;
                }
                .timeline-image {
                    width: 150px;
                    height: 150px;
                    margin-left: -75px;
                }
                &.timeline-inverted > .timeline-panel {
                    padding: 0 20px 20px;
                }
            }
            @media(min-width: 1200px) {
                min-height: 170px;
                .timeline-panel {
                    padding: 0 20px 20px 100px;
                }
                .timeline-image {
                    width: 170px;
                    height: 170px;
                    margin-left: -85px;
                    h4 {
                        margin-top: 40px;
                    }
                }
                &.timeline-inverted > .timeline-panel {
                    padding: 0 100px 20px 20px;
                }
            }
        }
        .timeline-heading {
            h4 {
                margin-top: 0;
                color: $primary;
                &.subheading {
                    text-transform: none;
                    color: $black;
                }
            }
        }
        .timeline-body {
            > ul,
            > p {
                margin-bottom: 0;
            }
        }
        @media(min-width: 768px) {
            &:before {
                left: 50%;
            }
            > li {
                min-height: 100px;
                margin-bottom: 100px;
                .timeline-panel {
                    float: left;
                    width: 41%;
                    padding: 0 20px 20px 30px;
                    text-align: right;
                    .date {
                        float: right;
                        right: -5px;
                    }
                }
                .timeline-image {
                    left: 50%;
                    width: 130px;
                    height: 130px;
                    margin-left: -65px;
                    h4 {
                        line-height: 18px;
                        margin-top: 30px;
                    }
                }
                &.timeline-inverted > .timeline-panel {
                    float: right;
                    padding: 0 30px 20px 20px;
                    text-align: left;
                    .date {
                        float: left;
                        left: -5px;
                    }
                }

            }
        }
    }
.date {
    color: $primary;
    padding: 45px 5px 0;
    position: relative;
    .day {
        font-size: 45px;
        left: 5px;
        line-height: 45px;
        position: absolute;
        top: 0;
    }
    .month {
        font-size: 25px;
        text-transform: uppercase;
        letter-spacing: 3px;
    }
    .year {
        @include transform(rotate(-90deg));
        filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
        display: block;
        position: absolute;
        right: -10px;
        top: 15px;
    }
}
</style>
