<template>
  <a :href="'#' + targetSection" class="btn btn-circle">
    <i
      :class="{
        'icon ion-md-arrow-dropup': isScrolledDown,
        'icon ion-md-arrow-dropdown': !isScrolledDown,
      }"
    ></i>
  </a>
</template>
<script>
import { defineComponent, ref, onMounted } from 'vue'

export default defineComponent({
  name: 'ScrollButton',
  setup () {
    const targetSection = ref('skills')
    const isScrolledDown = ref(false)

    const handleScroll = () => {
      // Switch direction arrow is pointing when scrolled down page
      if (window.scrollY >= 300) {
        isScrolledDown.value = true
        targetSection.value = 'page-top'
      } else {
        isScrolledDown.value = false
        targetSection.value = 'portfolio'
      }
    }

    onMounted(() => {
      handleScroll()
      window.addEventListener('scroll', handleScroll)
    })

    return { targetSection, isScrolledDown, handleScroll }
  }
})
</script>

<style lang='scss'>
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  .btn-circle {
    display: none;
    height: 55px;
    transition: opacity 0.3s ease-in-out;
    background: transparent;
    position: fixed;
    bottom: 15px;
    left: 50%;
    z-index: 1;
    opacity: 0.7;
    transform: translate(-50%);
    animation: bounce 2s infinite;
    @media (min-width: 992px) {
      display: initial;
    }
    &:focus,
    &:hover {
      opacity: 1;
      box-shadow: none;
    }
  }
</style>
