<template>
  <div id="app">
    <div>
      <router-view />
      <kirk-footer />
    </div>
  </div>
</template>

<script>
import KirkFooter from '@/components/kirk-footer.vue'

export default {
  name: 'App',
  components: {
    KirkFooter
  }
}
</script>
