<template>
    <footer class="pt-3 pb-3">
      <div class="container">
        <div class="row">
          <div class="col-sm-6">
            <span class="copyright">
              Copyright &copy; Henry Kirk {{ currentYear }}
            </span>
          </div>
          <div class="col-sm-6">
            <ul class="list-inline social-buttons">
              <li v-for="(link) in socialLinks" :key="link.id" class="list-inline-item">
                <a :href="link.url" target="_blank">
                  <i :class="link.icon" class="icon"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'KirkFooter',
  data () {
    return {
      socialLinks: [
        { id: 1, url: 'https://github.com/henrylkirk', icon: 'ion-logo-github' },
        { id: 2, url: 'https://www.facebook.com/henry.kirk.33', icon: 'ion-logo-facebook' },
        { id: 3, url: 'https://www.linkedin.com/in/henry-kirk-abb005134/', icon: 'ion-logo-linkedin' },
        { id: 4, url: 'https://www.instagram.com/capt.hkirk/?hl=en', icon: 'ion-logo-instagram' }
      ]
    }
  },
  computed: {
    currentYear () {
      return new Date().getFullYear()
    }
  }
})
</script>

<style lang="scss">

    footer {
        text-align: center;
        @include animated-gradient;
        span {
            font-size: 90%;
            line-height: 40px;
            text-transform: none;
            color: $light-gray;
        }
        ul.quicklinks {
            font-size: 90%;
            line-height: 40px;
            margin-bottom: 0;
            text-transform: none;
        }
    }

    ul.social-buttons {
        margin-bottom: 0;
        li {
            a {
                i {
                    @include transition(color 0.3s);
                    color: $white;
                }
                display: block;
                width: 40px;
                outline: none;
                @include transition(background-color 0.3s);
                @include box-shadow;
                &:active,
                &:focus,
                &:hover {
                    i {
                        color: $black;
                    }
                }
            }
        }
    }

</style>
