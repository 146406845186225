<template>
    <div class="col-md-4 portfolio-preview">
      <a v-if="link" :href="link" target="_blank" class="portfolio-link">
        <div class="portfolio-hover">
          <div class="portfolio-hover-content">
            <i class="icon icon-lg ion-ios-eye"></i>
          </div>
        </div>
        <img class="img-fluid" :src="'assets/img/portfolio/' + thumbImgName" alt="">
      </a>
      <div class="portfolio-caption">
        <a v-if="link" :href="link" target="_blank"><h4 class="text-white">{{ title }}</h4></a>
        <p class="text-muted">{{ summary }}</p>
      </div>
    </div>
  </template>

<script>
export default {
  name: 'PortfolioPreview',
  props: {
    title: {
      required: true,
      type: String
    },
    thumbImgName: {
      required: true,
      type: String
    },
    description: {
      required: true,
      type: String
    },
    summary: {
      required: true,
      type: String
    },
    link: {
      required: true,
      type: String
    }
  }
}
</script>

  <style lang="scss">
    .portfolio-preview {
      padding-right: 3px;
      padding-left: 3px;
      margin-bottom: 6px;
      overflow-y: hidden;
      border: 1px solid #dee2e6;
      img {
        transition: transform 0.3s ease-in-out;
      }
      &:hover {
        .portfolio-caption {
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
        }
      }
      .portfolio-link {
        overflow: hidden;
        position: relative;
        display: block;
        max-width: 400px;
        margin: 0 auto;
        cursor: pointer;
        &:hover {
          .portfolio-hover {
            opacity: 1;
          }
          img {
            transform: rotate3d(0, 1, 0, 10deg) scale(1.1);
          }
        }
        .portfolio-hover {
          position: absolute;
          z-index: 9;
          width: 100%;
          height: 100%;
          transition: opacity ease 0.3s;
          opacity: 0;
          background: rgba(255, 193, 7, 0.2);
          .portfolio-hover-content {
            font-size: 20px;
            position: absolute;
            top: 35%;
            width: 100%;
            height: 20px;
            margin-top: -12px;
            text-align: center;
            color: #fff;
            i {
              margin-top: -12px;
            }
            h3,
            h4 {
              margin: 0;
            }
          }
        }
      }
      .portfolio-caption {
        height: 100%;
        max-width: 400px;
        margin: 0 auto;
        padding: 25px;
        text-align: center;
        background-color: #000;
        transition: box-shadow 0.3s ease-in-out;
        h4 {
          margin: 0;
          text-transform: none;
        }
        p {
          font-size: 16px;
          font-style: italic;
          margin: 0;
        }
      }
    }
  </style>
