<template>
    <div>
		<div class="showcase-browser">
			<div class="browser-content">
				<div>
					<img src="/assets/img/capture-desktop.jpg" alt="Screenshot of a desktop layout" class="responsive">
				</div>
			</div>
		</div>
		<div class="showcase-phone">
			<div class="phone-content">
				<div>
					<img src="/assets/img/capture-mobile.jpg" alt="Screenshot of a mobile layout" class="responsive">
				</div>
			</div>
		</div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PortfolioPage'
})
</script>

<style lang="scss">
.showcase-browser {
    max-width: 925px;
    width: 100%;
    margin: 0 auto;
    &:before {
        content: '';
        display: block;
        padding-top: 3.1%;
        background: url("@/../public/assets/img/browser-top.png")no-repeat;
        @include background-cover;
    }
    .browser-content {
        position: relative;
        &:before {
            content: '';
            display: block;
            padding-top: 60%;
        }
        > div {
            overflow: auto;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            img {
                width: 100%;
            }
        }
    }
}
.showcase-phone {
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 220px;
    width: 100%;
    margin: 0 auto;
    &:before {
        content: '';
        display: block;
        padding-top: 29.5%;
        background: url("@/../public/assets/img/phone-top.png")no-repeat;
        @include background-cover;
    }
    &:after {
        content: '';
        display: block;
        padding-top: 28.8%;
        background: url("@/../public/assets/img/phone-bottom.png")no-repeat;
        @include background-cover;
    }
    .phone-content {
        background: #2a2e35;
        position: relative;
        &:before {
            background: #212227;
            content: '';
            display: block;
            padding-top: 153%;
            margin-right: 2%;
            margin-left: 2%;
        }
        > div {
            overflow: auto;
            position: absolute;
            padding: 0 4px;
            top: 0;
            left: 5%;
            right: 5%;
            bottom: 0;
            img {
                width: 100%;
            }
        }
    }
}
</style>
