<template>
  <span class="typewrite font-weight-bold">
    {{ text }}
    <span class="blink">|</span>
  </span>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'

export default defineComponent({
  name: 'TypeWriter',
  props: {
    period: {
      type: Number,
      default: 2000
    },
    words: Array
  },
  setup (props) {
    const isDeleting = ref(false)
    const text = ref('')
    const loopNum = ref(0)

    const type = () => {
      const i = loopNum.value % props.words.length
      const fullText = props.words[i]

      if (isDeleting.value) {
        text.value = fullText.substring(0, text.value.length - 1)
      } else {
        text.value = fullText.substring(0, text.value.length + 1)
      }

      let delta = 200 - Math.random() * 100

      if (isDeleting.value) {
        delta /= 2
      }

      if (!isDeleting.value && text.value === fullText) {
        delta = props.period
        isDeleting.value = true
      } else if (isDeleting.value && text.value === '') {
        isDeleting.value = false
        loopNum.value++
        delta = 500
      }

      setTimeout(() => {
        type()
      }, delta)
    }

    onMounted(() => {
      type()
    })

    return { text }
  }
})
</script>

<style lang="scss" scoped>
.blink {
  animation: Blink 1s infinite;
  font-style: normal;
  font-size: 50px;
  line-height: 40px;
}

@keyframes Blink {
  0% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
</style>
