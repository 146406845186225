<template>
    <div id="page-top">
        <kirk-navigation />
        <header class="masthead">
            <div class="me-image"></div>
            <div class="container">
                <div class="intro-text">
                    <div :class="'intro-lead-in'" style="font-size: 22px; font-style: italic; margin-bottom: 25px;">
                        <h1>Henry Kirk</h1>
                        <type-writer :period="1500" :words="['Developer Extraordinaire.', 'UX/UI Designer.', 'Full-Stack Developer.', 'Dog Lover.', 'Game Developer.']" />
                    </div>
                </div>
                <scroll-button />
            </div>
        </header>
        <section class="bg-pattern slant-right" id="portfolio">
            <div class="container">
                <div class="row">
                    <div class="col text-center">
                        <h2 class="section-heading text-uppercase">Portfolio</h2>
                        <h3 class="section-subheading">
                            Explore my design and development projects.
                        </h3>
                    </div>
                </div>
                <div class="row">
                    <portfolio-preview title="SimpliCollege" thumb-img-name="thumb-simplicollege.png"
                        alt-text="SimpliCollege project thumbnail"
                        summary="A teaching tool for highschool students to lower the cost of college."
                        description="SimpliCollege is a new tool for prospective college students to save money and learn about the college application process. I worked was the senior developer for this project and got to work with the client throughout the development cycle to continuously improve the application."
                        link="https://www.simplicollege.com" />

                    <portfolio-preview title="CMAC Events App" thumb-img-name="thumb-cmac.png"
                        alt-text="CMAC Events App project thumbnail"
                        summary="A mobile app for finding events and venue info."
                        description="The CMAC Events App is a hybrid mobile app for the CMAC music venue. Through the app users can find and purchase tickets for events, find parking information, and see FAQs about the venue. I got to develop a number of the tabs for this app, make API calls, design and implement animations, and publish several versions on the App Store and Google Play Store."
                        link="https://itunes.apple.com/us/app/cmac-concerts-events/id636503930?mt=8" />

                    <portfolio-preview title="New York Kitchen" thumb-img-name="thumb-nykitchen.png"
                        alt-text="New York Kitchen project thumbnail"
                        summary="An ecommerce site for buying culinary class tickets."
                        description="New York Kitchen is a website for purchasing tickets to culinary classes."
                        link="https://www.nykitchen.com" />
                </div>
                <div class="row">
                    <portfolio-preview title="RIT Graduate Admissions" thumb-img-name="thumb-rit.png"
                        alt-text="RIT Graduate Admissions project thumbnail"
                        summary="A website for current &amp; prospective grad students."
                        description="During my co-op at RIT's Undergraduate Admissions office, I was entrusted to develop a new Graduate Admissions website. I got experience designing within brand guidelines, requirements gathering, and creating custom admin panels for CMS ease."
                        link="https://www.rit.edu/emcs/ptgrad/" />

                    <portfolio-preview title="GoodWill Community" thumb-img-name="thumb-goodwill.jpeg"
                        summary="An app for tracking donations/donor data and spreading GoodWill's mission."
                        description="For JPMorgan Chase's 50th Code for Good Challenge, I developed GoodWill Community with the rest of Team 10. GoodWill wanted a mobile app for managing donations and increasing donor engagement. My team created an ionic app that gamified the donation process and allowed users to donate money through the app. In addition, we created a custom analytics tool for tracking user data. Our app ended up winning 1st place in the competition and JPMorgan will continue development on the project."
                        link="https://github.com/Delaware2018/team-10" />

                    <portfolio-preview title="Cannon Father" thumb-img-name="thumb-cannonfather.png"
                        summary="A piratey rogue-like game."
                        description="It's every retired-pirate-father's nightmare; a never ending onslaught of enemies from your past, hellbent on destroying what little remains of your family."
                        link="https://store.steampowered.com/app/1326660/Cannon_Father/" />

                </div>
            </div>
        </section>

        <!-- About -->
        <section id="about" class="slant-left">
            <div class="container">
                <div class="row">
                    <div class="col text-center">
                        <h2 class="section-heading text-uppercase">About Me</h2>
                        <h3 class="section-subheading">My career so far.</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <kirk-timeline />
                    </div>
                </div>
            </div>
        </section>

        <!-- Skills -->
        <section id="skills" class="slant-right bg-dark">
            <div class="container">
                <h2 class="my-skills text-right">
                    <span>MY</span>
                    <br>
                    <span>SKILLS</span>
                </h2>
                <div class="row text-center">
                    <div class="col mb-5">
                        <div class="hexagon mb-5">
                            <i class="icon icon-lg ion-ios-cart"></i>
                        </div>
                        <h4 class="service-heading text-white">E-Commerce</h4>
                        <p class="text-white">I have experience as a developer for Shopify and Woocommerce sites for a
                            number of clients including NYKitchen and gimMe Snacks.</p>
                    </div>
                    <div class="col mb-5">
                        <div class="hexagon mb-5">
                            <i class="icon icon-lg ion-ios-phone-portrait"></i>
                        </div>
                        <h4 class="service-heading text-white">Responsive Design</h4>
                        <p class="text-white">I design and develop websites that look great on desktops, tablets, and mobile
                            devices.</p>
                    </div>
                    <div class="col mb-5">
                        <div class="hexagon mb-5">
                            <i class="icon icon-lg ion-ios-lock"></i>
                        </div>
                        <h4 class="service-heading text-white">Web Security</h4>
                        <p class="text-white">Developing secure websites is a priority for me. I have experience with
                            developing a PCI compliant site.</p>
                    </div>
                </div>
                <div class="row text-center">
                    <div class="col mb-5">
                        <div class="hexagon mb-5">
                        <i class="icon icon-lg ion-ios-speedometer"></i>
                        </div>
                        <h4 class="service-heading text-white">Performance</h4>
                        <p class="text-white">
                        I optimize websites for speed and performance, ensuring fast load times and smooth user experiences.
                        </p>
                    </div>
                    <div class="col mb-5">
                        <div class="hexagon mb-5">
                            <i class="icon icon-lg ion-ios-game-controller-b"></i>
                        </div>
                        <h4 class="service-heading text-white">Game Development</h4>
                        <p class="text-white">
                        I have experience creating games using Unity and Unreal Engine, from mobile games to VR experiences.
                        </p>
                    </div>
                    <div class="col mb-5">
                        <div class="hexagon mb-5">
                        <i class="icon icon-lg ion-ios-gear"></i>
                        </div>
                        <h4 class="service-heading text-white">Full Stack Development</h4>
                        <p class="text-white">
                        I can work on both front-end and back-end development, using modern frameworks like Vue.js, React, Node.js, and Django.
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <!-- Contact Section -->
        <section id="contact" class="bg-pattern slant-left">
            <div class="container">
                <contact-form />
            </div>
        </section>

    </div>
</template>

<script>
import KirkNavigation from '@/components/kirk-navigation.vue'
import ContactForm from '@/components/contact-form.vue'
import PortfolioPreview from '@/components/portfolio-preview.vue'
import TypeWriter from '@/components/type-writer.vue'
import KirkTimeline from '@/components/kirk-timeline.vue'
import ScrollButton from '@/components/scroll-button.vue'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'HomePage',
  components: {
    KirkNavigation,
    ContactForm,
    PortfolioPreview,
    TypeWriter,
    KirkTimeline,
    ScrollButton
  }
})
</script>

<style lang='scss'>
// Header Section
header.masthead {
    text-align: center;
    color: $white;
    position: relative;
    z-index: 1;
    @include animated-gradient;

    .me-image {
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: .5;
        width: 100%;
        height: 100%;
        background-image: url('@/../public/assets/img/henry-background.jpeg');
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: right top;
        background-size: cover;
    }

    .intro-text {
        padding-top: 100px;
        padding-bottom: 100px;

        .intro-lead-in {
            font-size: 22px;
            font-style: italic;
            margin-bottom: 25px;
        }

        h1 {
            text-transform: uppercase;
            margin-bottom: 35px;
            text-shadow: 2px 2px 10px #0000005e;
            font-size: 90pt;

            @media(min-width: 1400px) {
                font-size: 230pt;
            }
        }
    }

    @media(min-width:768px) {
        .intro-text {
            padding-top: 160px;
            padding-bottom: 250px;

            .intro-lead-in {
                font-size: 40px;
            }
        }
    }
}

.hexagon {
    width: 140px;
    height: 80px;
    text-align: center;
    line-height: 80px;
    position: relative;
    left: 50%;
    background-color: transparent;
    transform: translate(-50%, -20%);
    box-shadow: inset 5px 0 0 0 $primary, inset -5px 0 0 0 $primary;

    .icon {
        color: $white;
        position: relative;
        z-index: 1;
    }

    &:before,
    &:after {
        background-color: transparent;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        border: $border-width solid $primary;
        border-top: 0;
        border-bottom: 0;
    }

    &:before {
        transform: rotate(120deg);
    }

    &:after {
        transform: rotate(-120deg);
    }

    &:hover {
        background-color: $primary;

        &:before,
        &:after {
            background-color: $primary;
        }
    }
}

#skills {
    padding: 16em 0 9em;
}

.my-skills {
    display: none;

    @media only screen and (min-width: 968px) {
        display: block;
        bottom: 65px;
        font-size: 416px;
        color: lighten($black, 70%);
        @import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:700');
        font-family: 'Roboto Condensed', sans-serif !important;
        position: absolute;
        right: 0;
        top: 0;
        letter-spacing: -20px;
        font-size: 450px;
        line-height: 75%;
        opacity: 0.1;
        z-index: 0;
        @include no-select;
    }
}
</style>
